<template>
<div>
  <section id="Cover">
    <img v-bind:src="Pic[0].media_details.sizes.large.source_url" alt="Cover">
  </section>
</div>
</template>

<script>
const apiHost = 'https://manuelrau.com'
const apiPath = `${apiHost}/wp-json`
export default {
  name: 'ArticleImageCover',
  data () {
    return {
      CoverPic: '',
      Pic: ''
    }
  },
  async mounted () {
    this.routChange()
  },
  methods: {
    routChange () {
      const articleId = this.$route.params.id
      let Article = ''
      console.log(articleId)
      fetch(`${apiPath}/wp/v2/media?parent=${articleId}&per_page=30`).then(r => {
        Article = r
        console.log(r)
        return r.json()
      }).then(d => {
        this.CoverPic = d
        console.log(this.CoverPic)
        const id = 40 // Cover ID
        this.Pic = this.CoverPic.filter(a => a.media_category[0] === id).map(a => a)
      })
        .catch(err => {
          console.log(1)
          console.error(err)
        })
      console.log(Article)
    }
  }
}
</script>

<style scoped lang="stylus">
 #Cover img
   display block
   height auto
  @media screen and (max-width 1200px)
   #Cover img
     margin-left auto
     margin-right auto
     justify-content center
     width 65%
     height auto
  @media screen and (max-width 600px)
    #Cover
      margin 0
      height 0
    #Cover img
      margin-left auto
      margin-right auto
      justify-content center
      width auto
      height 70%
</style>
