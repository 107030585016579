<template>
  <div class="container">
     <svg-screen/>
      <face/>
    <!-- <wifi/> -->
    <!-- <sound/> -->
  </div>

</template>

<script>

import face from '@/components/facetracking/face.vue'
import svgScreen from '@/components/facetracking/svgScreen'
// import wifi from "@//components/wifi.vue"
// import sound from "@//components/sound.vue"

export default {
  name: 'interactivPoster',
  components: {
    face,
    svgScreen
    // wifi,
    // sound

  }
}
</script>

<style>
.container{
  position: relative;
  width: 100vw;
  height: 100vh;
  display: inline-block;
  place-items: center;
  text-align: center;
}

</style>
