<template>
  <div>
    <section id="category">
      <div v-for="c in cat" :key="c.id">
        <button @click="changecat" :value="c.id" class="click">
          {{ c.name }} ({{c.count}})
        </button>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'categories',
  data () {
    return {
      cat: '',
      getcat: [],
      Posts: '',
      selected: {
        Categorie: ''
      }
    }
  },
  async mounted () {
    const Cat = await this.$store.dispatch('getCategories')
    this.Posts = await this.$store.dispatch('getOverviewPosts')
    console.log(Cat)
    this.cat = Cat.Categories
  },
  methods: {
    changecat (event) {
      console.log(event)
      // get id of button value
      const id = Number(event.target.value)
      console.log(id)
      // remove class when clicked
      const remove = document.getElementsByClassName('clickit')
      // when remove bigger then 0 change to click
      if (remove.length > 0) {
        remove[0].className = 'click'
        const click = document.getElementsByClassName('click')
        console.log(click)
      }
      // looking for the event and when you click change to clickit
      event.target.className = 'clickit'
      // get Element of clicked event
      const clicked = document.getElementsByClassName('clickit')
      console.log(clicked)
      this.$store.commit('selected', id)
    }
  }
}
</script>

<style scoped lang="stylus">
  button
    color black
    background-color whitesmoke
    border none
    font-family "IBM Plex Mono"
    font-weight 400
    font-size 2.6vh
  #category
    margin-left auto
    margin-right auto
    display: flex
    justify-content: space-evenly
  .click
    color black
  .clickit
    color orangered
  @media screen and (max-width 1200px)
    #category
      display none
      flex-direction column
      margin-left auto
      margin-right auto
      margin-bottom 20px
      align-items left
    button
      margin-right auto
      margin-left auto
      width 100vw
      font-size 40px
      padding 15px 0 15px 0
      margin-bottom 20px
  @media screen and (max-width 1400px)
    #category
      display none
  @media screen and (max-width 1000px)
    #category
      display none
      flex-direction column
      margin-left auto
      margin-right auto
      margin-bottom 20px
      align-items left
    button
      margin-right auto
      margin-left auto
      width 100vw
      padding 15px 0 15px 0
      margin-bottom 20px
  @media screen and (max-width 600px)
    #category
      display none
      flex-direction column
      margin-left auto
      margin-right auto
      margin-bottom 20px
      align-items left
    button
      margin-right auto
      margin-left auto
      width 100vw
      padding 15px 0 15px 0
      margin-bottom 20px
</style>
