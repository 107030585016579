import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Article from '@/views/Article'
import About from '@/views/About'
import AGB from '@/views/AGB'
import Face from '@/views/interactivPoster'
import LAB from '@/views/Lab'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/article/:id',
    name: 'Article',
    component: Article
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/agb',
    name: 'AGB',
    component: AGB
  },
  {
    path: '/face',
    name: 'Facetracking',
    component: Face
  },
  {
    path: '/lab',
    name: 'Lab',
    component: LAB
  }

]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
