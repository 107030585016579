<template>
  <div id="Title">
    <div id="T" v-for="(d, index) in Title" :key="d.length">
      <div v-if="newCat.selectedCategories[0] === undefined" id="content">
        <router-link :to="{ name: 'Article', params: {id: d.id}}" :style="{color: fontcolor()}" id="onlyTitel">
          {{ d.title.rendered }}
        </router-link>
        <p id="Cat">
          {{AllCat[index]}}
        </p>
      </div>
      <div v-else-if="d.categories[0] === newCat.selectedCategories[0]" id="content">
        <router-link :to="{ name: 'Article', params: {id: d.id}}" :style="{color: fontcolor()}" id="onlyTitel">
          {{ d.title.rendered }}
        </router-link>
        <p id="Cat">
          {{AllCat[index]}}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'content',
  props: [],
  data () {
    return {
      data: '',
      Title: '',
      AllCat: '',
      newCat: []
    }
  },
  async mounted () {
    const load = await this.$store.dispatch('getOverviewPosts')
    this.newCat = await this.$store.dispatch('getCategorieFilter')
    console.log(this.newCat.selectedCategories[0])
    console.log(load.posts)
    this.Title = load.posts
    console.log(this.Title)

    const cat = await this.$store.dispatch('getCategories')
    console.log(cat.Categories)
    this.categories = cat.Categories
    this.AllCat = this.showCat()
    console.log(this.AllCat)
    console.log(this.newCat)
  },
  methods: {
    fontcolor () {
      const colors = ['#000000']
      // const colors = ['#0093FF', '#FFE200', '#FF0027', '#00FE9D', '#FF00FF']
      console.log(Math.floor(Math.random() * colors.length))
      const RandomColor = colors[Math.floor(Math.random() * colors.length)]
      return RandomColor
      // document.getElementById('T').style.background = RandomColor
    },
    showCat () {
      const Categories = []
      for (let i = 0; i < this.Title.length; i++) {
        for (let a = 0; a < this.categories.length; a++) {
          for (let b = 0; b < this.Title[i].categories.length; b++) {
            if (this.Title[i].categories[b] === this.categories[a].id) {
              console.log(this.categories[a].name)
              const b = this.categories[a].name
              Categories.push(b)
            } else {
              // console.log(i, a, b)
            }
          }
        }
      }
      return Categories
    },
    width () {
      const widthW = window.innerWidth
      document.getElementById('T').style.width = widthW + 'px'
    }
  }
}
</script>

<style scoped lang="stylus">
  #T
    width 90vw
    font-family "IBM Plex Sans Text", sans-serif
    font-weight 400
    margin 0
    padding 1.2vh 0.8vw 1.2vh 0.8vw
    text-decoration none
    color black
    border-bottom black solid 3pt
  #T a
    font-size 65px
  a
    text-decoration none
    font-size 70px
    color black
  #Cat
    font-family "IBM Plex Mono", sans-serif
    font-size 30px
  #content
    display flex
    justify-content space-between
    align-items flex-end
  #onlyTitel
    transition: all 0.3s ease 0.1s
  #onlyTitel:hover
    letter-spacing 6px
    transition: all 0.3s ease 0.1s
    color orangered !important
  @media screen and (max-width 1200px)
    #onlyTitel:hover
      letter-spacing 0
    #content a
      font-size 62px
      padding 10px 5px 10px 5px
    #content p
      font-size 22px
    #T
      border-bottom black solid 3px
  @media screen and (max-width 1000px)
    #onlyTitel:hover
      letter-spacing 0
  @media screen and (max-width 600px)
    #onlyTitel:hover
      letter-spacing 0
    #Title
      margin-top 90%
    #content a
      font-size 32px
      padding 10px 5px 10px 5px
    #content p
      font-size 12px
    #T
      border-bottom black solid 2px
</style>
