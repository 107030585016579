<template>
<div id="backgroundImage">
   <img :src=getBackground() alt="Display">
</div>
</template>

<script>
export default {
  name: 'BackgroundImage',
  data () {
    return {
      Images: '',
      BackgroundCover: '',
      BackgroundCoverAll: ''
    }
  },
  async mounted () {
    const data = await this.$store.dispatch('getMedia')
    const id = 40
    this.Images = data.Image
    const CategoryMedia = await this.$store.dispatch('getMediaCategory')
    console.log(CategoryMedia)
    this.BackgroundCover = CategoryMedia.MediaCategory[1]
    console.log(this.Images.filter(a => a.media_category[0] === id))
    this.BackgroundCoverAll = this.Images.filter(a => a.media_category[0] === id).map(a => a)
    console.log(this.BackgroundCoverAll)
    this.getBackground()
  },
  methods: {
    getBackground () {
      const RandomPicture = this.BackgroundCoverAll[Math.floor(Math.random() * this.BackgroundCoverAll.length)]
      return RandomPicture.media_details.sizes.full.source_url
      // document.getElementById('Background').style.backgroundImage = RandomPicture.guid.rendered
    }
  }
}
</script>

<style scoped lang="stylus">
  img
    position: absolute
    left 50% /* position the left edge of the element at the middle of the parent */
    transform: translate(-50%, 5%) /* This is a shorthand of translateX(-50%) and translateY(-50%) */
    z-index -1
    top 0
    height 90%
    width auto
@media only screen and (max-width 1600px)
  img
    height 850px
    transform: translate(-50%, 5%)
@media screen and (max-width 1200px)
  img
    transform: translate(-50%, 5%)
@media screen and (max-width 600px)
  img
    transform: translate(-50%, 10%)

</style>
