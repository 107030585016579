
// function httpGetAsync (url, callback) {
//   var xmlHttp = new XMLHttpRequest()
//   xmlHttp.onreadystatechange = function () {
//     if (xmlHttp.readyState === 4 && xmlHttp.status === 200) { callback(xmlHttp.responseText) }
//   }
//   xmlHttp.open('GET', url, true) // true for asynchronous
//   xmlHttp.send(null)
// }
//
// var url = 'https://ipgeolocation.abstractapi.com/v1/?api_key=089e7fb6d611457db5beebf199dd9bf1'
// console.log(httpGetAsync(url))
// httpGetAsync(url)

const actions = {
  // const axios = require('axios')
  async getDataofUser () {
    const id = '089e7fb6d611457db5beebf199dd9bf1'
    const DataOfUser = await fetch(`https://ipgeolocation.abstractapi.com/v1/?api_key=${id}`).then(r => r.json())
      .catch(error => {
        console.log(error)
      })
    console.log(DataOfUser)
    return {
      DataOfUser
    }
  }
}
const geolocation = {
//   state,
//   mutations: {},
  actions
//   namespaced: true
}
//
export default geolocation
