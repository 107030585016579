
const state = {
  detections: null,
  wifi: null,
  sound: null
}
const mutations = {
  saveDetections (state, detections) {
    if (state.detections === undefined) {
      state.detections = 0
    } else {
      state.detections = detections
      console.log(detections)
    }
  },
  saveWifi (state, wifi) {
    state.wifi = wifi
  },
  saveSound (state, sound) {
    state.sound = sound
  }
}
const actions = {
  getDetections (state) {
    return state.detections
  },
  getWifi (state) {
    return state.wifi
  },
  getSound (state) {
    return state.sound
  }
}

const facetracking = {
  namespace: true,
  state,
  actions,
  mutations
}

export default facetracking
