const state = {
  getCategories: []
}
const actions = {
  getCategorieFilter ({ state }) {
    const selectedCategories = state.getCategories
    console.log(state.getCategories)
    return {
      selectedCategories
    }
  }
}
const mutations = {
  selected (state, newNumber) {
    // only get one categorie back and replace the old one
    if (state.getCategories.length >= 1) {
      state.getCategories.splice(0, 1, newNumber)
    } else {
      state.getCategories.push(newNumber)
    }
    console.log(state.getCategories)
  }
}
const categories = {
  namespace: true,
  state,
  actions,
  mutations
}

export default categories
