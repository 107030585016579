<template>
  <div id="all">
    <section id="Header">
      <router-link v-html="d.Pages[2].title.rendered" :to="{name: 'Home'}"> </router-link>
    </section>
    <section id="p-text">
      <p v-html="d.Pages[2].content.rendered">
      </p>
    </section>
    <section id="spotify">
      <iframe src="https://open.spotify.com/embed/show/5IiwmuY5UTqQgtH0YJ7p3T?theme=0" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
    </section>
  </div>
</template>

<script>
export default {
  name: 'About',
  data () {
    return {
      d: ''
    }
  },
  async mounted () {
    const page = await this.$store.dispatch('getPages')
    this.d = page
    console.log(this.d.Pages[0])
    const test = await this.$store.dispatch('getDataofUser')
    console.log(test)
  },
  methods: {
    background () {
      document.getElementsByName('body').style.backgroundColor = 'orangered'
    }
  }
}
</script>

<style scoped lang="stylus">
@import '~normalize.css';
@import '../CSS/global.styl';
@import '../CSS/scale.styl';

    #all
      height 110vh
      background-color orangered

    #Header
      text-align center
      font-family "IBM Plex Sans", sans-serif
      font-weight bold
      font-size 10vh
      margin 0
      padding 5vh 1vw 5vh 1vw
    #Header a
      color black
      transition: all 0.3s ease 0.1s
    #Header a:hover
      letter-spacing 6px
      transition: all 0.3s ease 0.1s
      color whitesmoke
    #p-text
      font-size 28px
      font-family "IBM Plex Sans", sans-serif
      line-height 37.4px
      color black
      margin-left auto
      margin-right auto
      width 90vw

    iframe
      margin-left auto
      margin-right auto
      display block
      width 90vw
      height auto

@media screen and (max-width 1200px)
  #Header a:hover
    letter-spacing 0
    color black
  p
    font-size 26px
    line-height 32px
@media screen and (max-width 1000px)
  #Header a:hover
    letter-spacing 0
    color black
  p
    font-size 26px
    line-height 29px
@media screen and (max-width 600px)
  #Header a:hover
    letter-spacing 0
    color black
  p
    font-size 20px
    line-height 22.3px
</style>
