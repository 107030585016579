<template>
<div class="fontbox">
  <textarea rows="2" cols="50" type="text">Hello this is a Font xyz</textarea>
</div>
</template>

<script>
export default {
  name: 'font',
  data () {
    return {
      id: ''
    }
  },
  async mounted () {
    await this.whichid()
  },
  methods: {
    whichid () {
      this.id = this.$route.params.id
      console.log(this.id)
      const text = document.getElementsByTagName('textarea')[0]
      if (this.id === 22) {
        text.textContent = 'Hello this is Jumbo! Type and see what you can do with it! XOXO'
        text.style.fontFamily = 'JUMBO'
      } else if (this.id === 138) {
        text.textContent = 'FKU  DIS  EJT  BLQ  AOP DNR DMT ANT FKU EIS DHR BMQ'
        text.style.fontFamily = 'Wetter'
      }
    }
  }
}
</script>

<style scoped lang="stylus">
  .fontbox
    margin-left auto
    margin-right auto
    width 90vw
    margin-top 15vh
    margin-bottom 30vh
    display flex
    justify-content center
  textarea
    font-family JUMBO
    font-size 10vh
    background  whitesmoke
    border none
    border-bottom 2px solid gray
@media screen and (max-width 1200px)
  textarea
    font-size 50px
@media screen and (max-width 1000px)
  textarea
    font-size 50px
@media screen and (max-width 600px)
  .fontbox
    margin-top 20px
    margin-bottom 20px
    height 250px
  textarea
    font-size 45px
</style>
