<template>
  <div>
    <div id="head">
      <h1 id="Name">Manuel Rau</h1>
      <section id="btn-about">
        <router-link :to="{name: 'About'}"> About </router-link>
      </section>
    </div>
    <div id="background">
      <BackgroundImage />
    </div>
    <section>
      <categories />
    </section>
    <section id="Headline">
      <Content />
    </section>
    <section>
      <End />
    </section>
  </div>
</template>

<script>
import Content from '@/components/content'
import End from '@/components/footer'
import categories from '@/components/categories'
import BackgroundImage from '@/components/BackgroundImage'
export default {
  name: 'Home',
  components: { BackgroundImage, categories, Content, End },
  data () {
    return {
      data: [],
      article: []
    }
  },
  async mounted () {
    // console.log(this.$route.params.id)
    const load = await this.$store.dispatch('getOverviewPosts')
    console.log(load)
    this.data = load
    console.log(this.data.posts[0].id)
    // this.rotate()
    this.getWindowHeight()
    // await fetch('http://manuelrau.com/wp-json/wp/v2/posts')
    //   .then(r => r.json())
    //   .then(d => {
    //     this.article = d
    //     console.log(this.article)
    //   })
  },
  methods: {
    getWindowHeight () {
      console.log(window.innerWidth)
      const a = window.innerWidth
      // const b = window.innerHeight
      if (a < 1200) {
        document.getElementById('background').style.marginBottom = window.innerHeight - 50 + 'px'
        console.log(window.innerHeight)
      } else if (a < 600) {
        document.getElementById('background').style.marginBottom = window.innerHeight + 'px'
      } else {
        document.getElementById('background').style.marginBottom = window.innerHeight - 100 + 'px'
        console.log(window.innerHeight)
      }
    }
    // rotate () {
    //   if (screen.orientation.angle === 90) {
    //     document.getElementsByTagName('body')[0].style.display = 'none'
    //     console.log('ndcbdbabdnjwnendjn')
    //   }
    // }
  }
  // click () {
  //   const c = '#0093FF'
  //   console.log(1)
  //   console.log(c)
  //   document.getElementById('Name').style.display = 'none'
  //   // document.getElementById('head').style.display = 1
  // }
}
</script>

<style scoped lang="stylus">
ul
  padding 0
ul li
  text-decoration none
a
  text-decoration none
  font-size 25px
  display flex
  justify-content center
  margin 2vh 0 0 0
#btn-about a
  margin 0 0 0 0
  transition: all 0.3s ease 0.1s
#btn-about a:hover
  transition: all 0.3s ease 0.1s
  color orangered !important
#head
  margin-left auto
  margin-right auto
  width: 90vw
  display: flex
  flex-direction row
  justify-content space-between
  align-items: baseline
#Headline
  margin-left: auto
  margin-right: auto
  width: 90vw
#filter
  border-bottom black solid 5px
  display flex
  justify-content space-around
@media screen and (max-width 600px)
    h1
      transform rotate(0)
    #head
      width 95%
      justify-content space-between
</style>
