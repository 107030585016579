
export const convert = (num, inMin, inMax, outMin, outMax) => {
  return (num - inMin) * (outMax - outMin) / (inMax - inMin) + outMin
}

export const getRandomNumber = (from, to) => {
  return Math.floor(Math.random() * (to - from + 1)) + from
}

// Expects [x, y], [x, y]
export const distanceBetween = (first, second) => {
  const x = first.x - second.x
  const y = first.y - second.y
  return Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2))
}

// Averade Position of multiple Points: num1 is start index, num2 end index
// data = detections
export const averagePosition = (data, num1, num2) => {
  let totalX = 0
  let totalY = 0
  let averageX = 0
  let averageY = 0
  const numAmount = Math.abs(num1 - num2)

  for (let i = 0; i < numAmount; i++) {
    const xNum = data.landmarks.positions[num1 + i].x
    totalX += xNum

    const yNum = data.landmarks.positions[num1 + i].y
    totalY += yNum
  }
  averageX = totalX / numAmount
  averageY = totalY / numAmount
  return {
    x: averageX,
    y: averageY
  }
}

// expects [x,y] [x,y]
export const averagePoint = (point1, point2) => {
  const averageX = (point1.x + point2.x) / 2
  const averageY = (point1.y + point2.y) / 2
  return {
    x: averageX,
    y: averageY
  }
}

export const cosSatz = (a, b, c) => {
  const radian = Math.acos((a * a + c * c - b * b) / (2 * a * c))
  return radian * (180 / Math.PI)
}

export const getAngle = (x1, y1, x2, y2) => {
  const dy = y2 - y1
  const dx = x2 - x1
  let theta = Math.atan2(dy, dx) // range (-PI, PI]
  theta *= 180 / Math.PI // rads to degs, range (-180, 180]
  return theta
}

export const mirror = (num, min, max) => {
  const center = (max - (min < 0 ? min * -1 : min)) / 2
  if (typeof num === 'number') {
    if (num < center) {
      return convert(num, min, center, max, center)
    } else if (num > center) {
      return convert(num, center, max, center, min)
    } else {
      return num
    }
  } else {
    console.error(`Num at mirror function ist not a Number! ${num} is ${typeof num}`)
  }
}
