<template>
<div>
    <div class="carousel">
      <div class="carousel_actions">
        <button id="carousel_button--prev" aria-label="Previous" v-on:click="previous()"> &#706; </button>
        <button id="carousel_button--next" aria-label="Next" v-on:click="next()"> &#707; </button>
      </div>
        <div v-for="(c, index) in carousel" :key="c.id">
          <div v-if="index === 0" class="carousel_item carousel_item-visible">
              <img :src="c.source_url"/>
          </div>
          <div v-else class="carousel_item">
            <img :src="c.source_url"/>
          </div>
        </div>
    </div>
</div>
</template>

<script>
const apiHost = 'https://manuelrau.com'
const apiPath = `${apiHost}/wp-json`
export default {
  name: 'carousel',
  data () {
    return {
      content: '',
      image: '',
      slidePosition: 0,
      carousel: []
    }
  },
  async mounted () {
    this.routChange()
    this.carouselon()
  },
  methods: {
    routChange () {
      const articleId = this.$route.params.id
      let Article = ''
      console.log(articleId)
      fetch(`${apiPath}/wp/v2/media?parent=${articleId}`).then(r => {
        Article = r
        console.log(r)
        return r.json()
      }).then(d => {
        this.content = d
        console.log(d)
        const i = 'image'
        const carousel = 39
        this.image = this.content.filter(a => a.media_type === i).map(a => a) // Filter Inhalte nach Bildern und gebe diese zurück
        this.carousel = this.image.filter(a => a.media_category[0] === carousel).map(a => a) // Filter alle Bilder die zu einem Carousel zusammengetragen werden sollen
        console.log(this.carousel)
        console.log(this.file.length)
        if (this.file.length === 0 || undefined) {
          this.file = 0
        }
      })
      console.log(Article)
    },
    previous () {
      const slides = document.getElementsByClassName('carousel_item')
      const totalSlides = slides.length
      if (this.slidePosition === 0) {
        this.slidePosition = totalSlides - 1
      } else {
        this.slidePosition--
      }
      for (const slide of slides) {
        slide.classList.remove('carousel_item-visible')
        slide.classList.add('carousel_item--hidden')
      }
      slides[this.slidePosition].classList.add('carousel_item-visible')
      console.log(slides[this.slidePosition])
      console.log(this.slidePosition)
    },
    next () {
      const slides = document.getElementsByClassName('carousel_item')
      const totalSlides = slides.length
      if (this.slidePosition === totalSlides - 1) {
        this.slidePosition = 0
      } else {
        this.slidePosition++
      }
      for (const slide of slides) {
        slide.classList.remove('carousel_item-visible')
        slide.classList.add('carousel_item--hidden')
      }
      slides[this.slidePosition].classList.add('carousel_item-visible')
      console.log(this.slidePosition)
    },
    carouselon () {
      if (this.carousel === []) {
        document.getElementsByClassName('carousel').style.display = 'none'
      } else {
        document.getElementByClassName('carousel').style.display = 'block'
      }
    }
  }
}
</script>

<style scoped lang="stylus">
  .carousel
    overflow hidden
    margin-left auto
    margin-right auto
    width 90vw
    padding-top 65px
    position relative
  .carousel .carousel_item
  .carousel .carousel_item--hidden
    display none
  .carousel .carousel_item img
    height auto
    max-width 35%
    width 800px
  .carousel .carousel_item-visible
    display block
  .carousel .carousel_actions
    display flex
    position absolute
    z-index 1
    width 70%
    padding-bottom 20px
    font-size 3.3rem
    // justify-content space-between
    top 5%
    transform translateY(-50%)
  .carousel .carousel_actions button
    border 0
    margin 10px
    border-radius 60px
    font-family "IBM Plex Mono"
    font-width 400
    font-size 3.3rem
    width 35px
    height 35px
    color black
    background-color transparent
@media screen and (max-width 1400px)
    .carousel
    justify-content center
    padding-bottom 5px
    .carousel .carousel_item img
      max-width 100%
      height auto
      width 600px
    .carousel .carousel_actions button
      font-size 1.7rem
      font-width 600
      margin 10px
      width 50px
      height 50px
@media screen and (max-width 1200px)
    .carousel
      justify-content center
      padding-bottom 5px
    .carousel .carousel_item img
      max-width 100%
      height auto
      width 600px
    .carousel .carousel_actions button
      font-size 1.7rem
      font-width 600
      margin 10px
      width 50px
      height 50px
@media screen and (max-width 1000px)
  .carousel
    justify-content center
    padding-bottom 5px
  .carousel .carousel_item img
    max-width 100%
    height auto
    width 600px
  .carousel .carousel_actions button
    font-size 1.2rem
    font-width 600
    margin 10px
    width 50px
    height 50px
@media screen and (max-width 600px)
  .carousel
    justify-content center
    padding-bottom 5px
  .carousel .carousel_item img
    max-width 100%
    height auto
    width 90vw
  .carousel .carousel_actions
    justify-content space-between
    width 100%
  .carousel .carousel_actions button
    font-family "IBM Plex Mono"
    font-width 600
    font-size 1.5rem
    margin 5px
    width 30px
    height 30px
</style>
