<template>
  <div>
    <section>
      <div id="front">
        <div id="front-block">
          <div id="Header">
            <router-link :to="{name: 'Home'}">{{article.title.rendered}}</router-link>
          </div>
          <div id="info">
            <p v-html="text"></p>
          </div>
        </div>
        <ArticleImageCover />
      </div>
    </section>
  </div>
</template>

<script>
import ArticleImageCover from '@/components/ArticleImageCover'
const apiHost = 'https://manuelrau.com'
const apiPath = `${apiHost}/wp-json`
export default {
  name: 'Text',
  components: { ArticleImageCover },
  data () {
    return {
      data: '',
      Text: '',
      article: '',
      text: '',
      SelectedFilter: ''
    }
  },
  async mounted () {
    this.routChange()
  },
  methods: {
    routChange () {
      const articleId = this.$route.params.id
      let Article = ''
      console.log(articleId)
      fetch(`${apiPath}/wp/v2/posts/${articleId}`).then(r => {
        Article = r
        // console.log(r)
        return r.json()
      }).then(d => {
        this.article = d
        // console.log(d)
        this.text = this.article.content.rendered.split('</p>')[0] // filter alle Bilder und tags heraus
      })
      console.log(Article)
    }
  }
}
</script>

<style scoped lang="stylus">
@import '~normalize.css';
@import '../CSS/global.styl';
@import '../CSS/scale.styl';
  a
    text-decoration none
    color black
    transition: all 0.3s ease 0.1s
  #Header a
    transition: all 0.3s ease 0.1s
  #Header a:hover
    letter-spacing 6px
    transition: all 0.3s ease 0.1s
    color orangered
  h1
    color black
    text-align center
    font-size 80px
    margin 0
    padding 5vh 1vw 5vh 1vw
  #Header
    color black
    display block
    text-align center
    font-family "IBM Plex Sans", sans-serif
    font-weight bold
    font-size 80px
    margin 1vh 2vw 1vh 1vw
  #front-block
    display block
  #front
    justify-content space-around
    display flex
    margin 0 0 5vh 0
  #info
    font-size 25px
    line-height 28px
  #info p
    color black
    font-family "IBM Plex Sans", serif
    font-weight 400
    font-style normal
    font-size 28px
    line-height 38px
    padding 0.5vh 3vw 2vh 3vw
    margin 0
  p
    text-indent 100px
@media screen and (max-width 1200px)
    #Header a:hover
      letter-spacing 0
    #Header
      margin 20px 0 20px 0
      font-size 60px
    #info
      text-indent 10px
    p
      font-size 26px
      line-height 32px
    #front
      display block
@media screen and (max-width 1000px)
    #info
      text-indent 10px
    p
      font-size 26px
      line-height 29px
@media screen and (max-width 600px)
  #Header a:hover
    letter-spacing 0
  #Header
    margin 20px 0 20px 0
    font-size 36px
  #info p
    text-indent 0
    font-size 20px
    line-height 23.3px
  #front
    display block

</style>
