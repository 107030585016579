<template>
  <div>
<!--    <section id="head-pic">-->
<!--      <img v-bind:src="pic[0].media_details.sizes.large.source_url" alt="Cover" />-->
<!--    </section>-->
    <section>
      <div id="font">
        <font />
      </div>
    </section>
    <section id="video">
      <div v-for="f in file" :key="f.id" id="videoeinzeln">
        <video :src="f.source_url" controls muted loop type="video/mp4"></video>
      </div>
    </section>
    <section>
      <div id="carousel">
        <carousel />
      </div>
    </section>
<!--    <section id="carousel">-->
<!--        <div class="slider">-->
<!--          <a v-for="(c, index) in carousel" :key="c.id" :href="`#slide-${c.id}`"> {{ index }}</a>-->
<!--        <div class="slides">-->
<!--          <div v-for="c in carousel" :key="c.id" :id="`slide-${c.id}`">-->
<!--            <img :src="c.source_url"/>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </section>-->
    <section id="images">
      <div v-for="(i, index) in imagesoc" :key="i.id">
        <div v-if="isEven(index) || 0">
          <img id="gerade" v-bind:src="i.media_details.sizes.full.source_url" alt="a"/>
        </div>
        <div v-else>
          <img id="ungerade" v-bind:src="i.media_details.sizes.full.source_url" alt="b"/>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import carousel from '@/components/carousel'
import Font from '@/components/font'
const apiHost = 'https://manuelrau.com'
const apiPath = `${apiHost}/wp-json`
export default {
  name: 'Images',
  components: { Font, carousel },
  data () {
    return {
      articleId: '',
      content: '',
      image: '',
      imagesoc: '',
      carousel: [],
      file: ''
    }
  },
  async mounted () {
    await this.routChange()
    console.log(this.file)
    await this.video()
    await this.fontline()
    await this.carouselinuse()
  },
  methods: {
    async routChange () {
      this.articleId = this.$route.params.id
      let Article = ''
      console.log(this.articleId)
      await fetch(`${apiPath}/wp/v2/media?parent=${this.articleId}&per_page=30`).then(r => {
        Article = r
        return r.json()
      }).then(d => {
        this.content = d
        const i = 'image'
        const f = 'file'
        const carousel = 39
        const none = 0
        this.image = this.content.filter(a => a.media_type === i).map(a => a) // Filter Inhalte nach Bildern und gebe diese zurück
        this.file = this.content.filter(a => a.media_type === f).map(a => a) // Filter Inhalte nach Video und gebe diese zurück
        this.imagesoc = this.image.filter(a => a.media_category.length === none).map(a => a) // Filter ohne Bilder von Carousel und Cover
        this.carousel = this.image.filter(a => a.media_category[0] === carousel).map(a => a) // Filter alle Bilder die zu einem Carousel zusammengetragen werden sollen
        if (this.file.length === 0) {
          this.file = 0
        }
      })
      console.log(Article)
      console.log(this.file)
      console.log(this.imagesoc)
    },
    isEven (n) {
      return n % 2 === 0
    },
    video () {
      if (this.file === 0) {
        document.getElementById('video').style.display = 'none'
      } else {
        for (let i = 0; i < this.file.length; i++) {
          console.log(i)
          const height = this.file[i].media_details.height
          const width = this.file[i].media_details.width
          console.log(width)
          if (height < width) {
            document.getElementsByTagName('video')[i].style.maxWidth = '60%'
          } else if (height === width) {
            document.getElementsByTagName('video')[i].style.maxWidth = '70%'
          } else {
            document.getElementsByTagName('video')[i].style.maxWidth = '35%'
          }
        }
      }
    },
    async fontline () {
      if (this.articleId === 22 || this.articleId === 138) {
        document.getElementById('font').style.display = 'block'
      } else {
        document.getElementById('font').style.display = 'none'
      }
    },
    async carouselinuse () {
      console.log(this.carousel.length)
      if (this.carousel.length === 0) {
        document.getElementById('carousel').style.display = 'none'
      }
    }
  }
}
</script>

<style scoped lang="stylus">
  #forAll
    margin-left auto
    margin-right auto
    width 90vw
    display grid
    grid-template-columns auto auto
    align-items start
    justify-items center
  #images
    margin-top 1vh
    display flex
    flex-wrap wrap
    justify-content space-around
  #gerade
    margin-top 10vh
    margin-bottom 10vh
    padding 5vh 5vw
    height 700px
    display inline-grid
  #ungerade
    height 700px
    padding 3vh 3vw
    display inline-grid
  #head-pic
    margin-top 2vh
    margin-bottom 2vh
    margin-left auto
    margin-right auto
    width 90vw
    display flex
    justify-content center
  #head-pic img
    height 100vh
  #video
    display flex
    flex-direction row
    margin-top 2vh
    margin-bottom 100px
    margin-left auto
    margin-right auto
    width 90vw
  #videoeinzeln
    display flex
    flex-direction column
    margin-top 2vh
    margin-bottom 100px
    margin-left auto
    margin-right auto
    width 90vw
  video
    max-height auto
    max-width 30%
  #font
    display none
@media screen and (max-width 1200px)
  video
    height auto
    max-width 100% !important
  #video
    display block
  #videoeinzeln
    display flex
    justify-content center
    margin-top 2vh
    margin-bottom 100px
    margin-left auto
    margin-right auto
    width 90%
  #gerade
    width 90%
    height auto
  #ungerade
    width 90%
    height auto
@media screen and (max-width 1000px)
  video
    height 500px
    max-width 90% !important
  #video
    display block
  #videoeinzeln
    display flex
    margin-top 2vh
    margin-bottom 100px
    margin-left auto
    margin-right auto
    width 90%
  #gerade
    width 90%
    height auto
  #ungerade
    width 90%
    height auto
@media screen and (max-width 600px)
  video
    height 400px
    max-width 100% !important
  #video
    display block
  #videoeinzeln
    display flex
    justify-content center
    margin-top 2vh
    margin-bottom 1vh
    margin-left auto
    margin-right auto
    width 90%
  #gerade
    margin-top 2vh
    margin-bottom  2vh
    width 90%
    height auto
  #ungerade
    margin-top 2vh
    margin-bottom  2vh
    width 90%
    height auto
</style>
