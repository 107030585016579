<template>
  <div id="app">
    <transition
      mode="out-in"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
    >
    <router-view />
    </transition>
  </div>
</template>
<script>

export default {
  name: 'App',
  components: {

  }
}
</script>
<style lang="stylus">
  @import '~normalize.css';
  @import 'CSS/global.styl';
  @import 'CSS/scale.styl'
  @import 'assets/main.css'
#app
  font-family Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  color #2c3e50
</style>
