import {
  convert,
  averagePoint,
  averagePosition,
  getAngle,
  distanceBetween,
  cosSatz,
  mirror
} from './helpers'

export default function (detections) {
  if (detections && detections.length > 0) {
    detections = detections[0]

    // Input Width
    const IMG_WIDTH = detections.detection.imageWidth
    const IMG_HEIGHT = detections.detection.imageHeight
    // SVG WIDTH WHEN VERTICAL
    const SVG_WIDTH = 2160
    const SVG_WIDTH_V = 6827
    const SVG_HEIGHT = 3840

    // eslint-disable-next-line no-inner-declarations
    function sConX (x) {
      const min = ((SVG_WIDTH_V / 2) - (SVG_WIDTH / 2)) * -1
      const max = (SVG_WIDTH_V / 2) + (SVG_WIDTH / 2)
      return mirror(convert(x, 0, IMG_WIDTH, min, max), min, max)
    }

    // eslint-disable-next-line no-inner-declarations
    function sConY (y) {
      return convert(y, 0, IMG_HEIGHT, 0, SVG_HEIGHT)
    }

    // Face Positions
    // Eyes
    const centerPointRightEye = averagePosition(detections, 36, 41)
    const centerPointLeftEye = averagePosition(detections, 42, 47)
    const centerPointEyes = averagePoint(centerPointLeftEye, centerPointRightEye)
    // Eyebrows
    const centerRightEyebrow = averagePosition(detections, 17, 21)
    const centerLeftEyebrow = averagePosition(detections, 22, 26)
    const centerPointEyebrows = averagePoint(centerRightEyebrow, centerLeftEyebrow)
    // Nose
    const centerPointNose = averagePosition(detections, 27, 35)
    const noseTip = detections.landmarks.positions[33]
    // Mouth
    const centerPointMouth = averagePosition(detections, 48, 67)
    // Chin
    const chin = detections.landmarks.positions[8]
    // Ears
    const leftEar = detections.landmarks.positions[0]
    const rightEar = detections.landmarks.positions[16]

    // DISTANCES BETWEEN FACE LANDMARKS
    const distanceEyebrowsEyes = distanceBetween(centerPointEyebrows, centerPointEyes)
    const distanceEyesNose = distanceBetween(centerPointEyes, centerPointNose)
    const distanceNoseMouth = distanceBetween(centerPointNose, centerPointMouth)
    const distanceMouthChin = distanceBetween(centerPointMouth, chin)
    const totalFaceHeight = distanceEyebrowsEyes + distanceEyesNose + distanceNoseMouth + distanceMouthChin

    // Face Behavior
    // Face Rotation
    const faceDegree = (getAngle(chin.x, chin.y, centerPointEyebrows.x, centerPointEyebrows.y)) * -1
    // MouthOpening
    const lipDistance = distanceBetween(detections.landmarks.positions[62], detections.landmarks.positions[66])
    let mouthOpening = (lipDistance * 100) / totalFaceHeight
    mouthOpening = mouthOpening > 5
    // FACETilt
    const aSeite = distanceBetween(detections.landmarks.positions[8], detections.landmarks.positions[4])
    const bSeite = distanceBetween(detections.landmarks.positions[4], detections.landmarks.positions[12])
    const cSeite = distanceBetween(detections.landmarks.positions[12], detections.landmarks.positions[8])
    let faceAngle = cosSatz(aSeite, bSeite, cSeite)
    if (faceAngle >= 95 && faceAngle <= 105) {
      faceAngle = 105
    }

    // Expressions
    const surprised = detections.expressions.surprised > 0.6
    const happy = detections.expressions.happy > 0.6
    const angry = detections.expressions.angry > 0.3
    const sad = detections.expressions.sad > 0.2
    const fearful = detections.expressions.fearful > 0.1
    const disgusted = detections.expressions.disgusted > 0.1
    const neutral = detections.expressions.neutral > 0.7

    // Client Data
    // Age
    const age = Math.floor(detections.age)
    // Gender
    const gender = detections.gender

    // Ready for Export
    return {
      positions: {
        leftEye: {
          x: sConX(centerPointLeftEye.x),
          y: sConY(centerPointLeftEye.y)
        },
        rightEye: {
          x: sConX(centerPointRightEye.x),
          y: sConY(centerPointRightEye.y)
        },
        leftEyebrow: {
          x: sConX(centerLeftEyebrow.x),
          y: sConY(centerLeftEyebrow.y)
        },
        rightEyebrow: {
          x: sConX(centerRightEyebrow.x),
          y: sConY(centerRightEyebrow.y)
        },
        centerNose: {
          x: sConX(centerPointNose.x),
          y: sConY(centerPointNose.y)
        },
        noseTip: {
          x: sConX(noseTip.x),
          y: sConY(noseTip.y)
        },
        mouth: {
          x: sConX(centerPointMouth.x),
          y: sConY(centerPointMouth.y)
        },
        chin: {
          x: sConX(chin.x),
          y: sConY(chin.y)
        },
        leftEar: {
          x: sConX(leftEar.x),
          y: sConY(leftEar.y)
        },
        rightEar: {
          x: sConX(rightEar.x),
          y: sConY(rightEar.y)
        }
      },
      behavior: {
        faceRotation: faceDegree,
        faceTilt: faceAngle,
        mouthOpen: mouthOpening,
        happy: happy,
        angry: angry,
        sad: sad,
        fearful: fearful,
        surprised: surprised,
        disgusted: disgusted,
        neutral: neutral
      },
      client: {
        age: age,
        gender: gender
      }
    }
  }
}
