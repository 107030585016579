import Vue from 'vue'
import Vuex from 'vuex'
import wpStore from './wp-store'
import categories from '@/store/categories'
import facetracking from '@/store/facetracking'
import geolocation from '@/store/geolocation'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    wp: wpStore,
    cat: categories,
    face: facetracking,
    geo: geolocation
  }
})
