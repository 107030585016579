<template>
  <svg x="0" y="0" viewBox="0 0 2160 3840">

      <g ref="svgone">

      </g>

      <g ref="svgtwo">

      </g>

      <g ref="svgthree">

      </g>

      <g ref="svgfour">

      </g>

      <g ref="gridGroup">

      </g>

      <rect id="svg-background"  />

  </svg>
</template>

<script>
// import { convert } from '@/helpers/helpers'

export default {
  name: 'svgScreen',
  mounted () {
    // let gridGroup = this.$refs['gridGroup']
    const svgone = this.$refs.svgone
    const svgtwo = this.$refs.svgtwo
    const svgthree = this.$refs.svgthree
    const svgfour = this.$refs.svgfour

    // let svg = this.$refs['screenSVG']

    this.$store.subscribe((mutation, state) => {
      console.log(state)
      console.log(state.wifi)
      console.log(state.face.detections)
      console.log(state.face.detections.behavior.faceRotation)
      console.log(state.sound)

      // this.createGrid(gridGroup, state.wifi)

      this.svgoneint(svgone, state.face.detections.behavior.faceRotation)
      this.svgtwoint(svgtwo, state.face.detections.behavior.faceRotation)
      this.svgintthree(svgthree, state.face.detections.behavior.faceRotation)
      this.svgintfour(svgfour, state.face.detections.behavior.faceRotation)
      this.d = state.face.detections.behavior.faceRotation
      const sound = state.sound
      const setBg = (sound) => {
        const randomColor = Math.floor(Math.random() * 16777215 * sound).toString(16)
        document.body.style.background = '#' + randomColor
        console.log(sound)
      }
      this.color = setBg(sound)
    })
  },
  methods: {

    svgoneint: function (svg, d) {
      svg.innerHTML = ''
      svg.innerHTML = `
            <g class="arroundcenter" transform="rotate(${d}, 1079.41, 2025.83)">
               <rect class="cls-1" x="232.8"  y="2255.08" width="114.63" height="114.63"" />
              <rect class="cls-2" x="232.8" y="2140.46" width="114.63" height="114.63"/>
              <rect class="cls-1" x="232.8" y="2025.83" width="114.63" height="114.63"/>
              <rect class="cls-2" x="232.8" y="1911.2" width="114.63" height="114.63"/>
              <rect class="cls-1" x="232.8" y="1796.58" width="114.63" height="114.63"/>
              <rect class="cls-2" x="232.8" y="1681.95" width="114.63" height="114.63"/>
              <rect class="cls-1" x="1813.02" y="2255.08" width="114.63" height="114.63"/>
              <rect class="cls-2" x="1813.02" y="2140.46" width="114.63" height="114.63"/>
              <rect class="cls-1" x="1813.02" y="2025.83" width="114.63" height="114.63"/>
              <rect class="cls-2" x="1813.02" y="1911.2" width="114.63" height="114.63"/>
              <rect class="cls-1" x="1813.02" y="1796.58" width="114.63" height="114.63"/>
              <rect class="cls-2" x="1813.02" y="1681.95" width="114.63" height="114.63"/>
              <circle class="cls-3" cx="1079.41" cy="2025.83" r="959.59"/>
            </g>`
    },

    svgtwoint: function (svg, d) {
      svg.innerHTML = ''
      svg.innerHTML = `
            <g transform="rotate(${d}, 1079.41, 2025.83)">
              <rect class="cls-4" x="470" y="861.86" width="216.76" height="335.39"/>
              <rect class="cls-5" x="470" y="1197.25" width="216.76" height="335.39"/>
              <rect class="cls-4" x="470" y="1532.65" width="216.76" height="335.39"/>
              <rect class="cls-5" x="470" y="1861.57" width="216.76" height="335.39"/>
              <rect class="cls-4" x="470" y="2196.96" width="216.76" height="335.39"/>
              <rect class="cls-5" x="470" y="2532.35" width="216.76" height="335.39"/>
              <rect class="cls-4" x="470" y="2867.75" width="216.76" height="335.39"/>
              <rect class="cls-4" x="1463.24" y="861.86" width="216.76" height="335.39"/>
              <rect class="cls-5" x="1463.24" y="1197.25" width="216.76" height="335.39"/>
              <rect class="cls-4" x="1463.24" y="1532.65" width="216.76" height="335.39"/>
              <rect class="cls-5" x="1463.24" y="1861.57" width="216.76" height="335.39"/>
              <rect class="cls-4" x="1463.24" y="2196.96" width="216.76" height="335.39"/>
              <rect class="cls-5" x="1463.24" y="2532.35" width="216.76" height="335.39"/>
              <rect class="cls-4" x="1463.24" y="2867.75" width="216.76" height="335.39"/>

            </g>`
    },
    svgintthree: function (svg, d) {
      svg.innerHTML = ''
      svg.innerHTML = `
         <g>
            <g transform="rotate(${d}, 306.49, 360.75)">
              <ellipse class="cls-6" cx="306.49" cy="360.75" rx="203.51" ry="203.75"/>
              <rect x="291.5" y="260" width="25" height="203.5"/>
              <rect x="214" y="260" width="62" height="13"/>
              <rect class="cls-7" x="214" y="273" width="62" height="13"/>
              <rect x="214" y="286" width="62" height="13"/>
              <rect class="cls-7" x="214" y="299" width="62" height="13"/>
              <rect x="214" y="312" width="62" height="13"/>
              <rect class="cls-7" x="214" y="325" width="62" height="13"/>
              <rect x="214" y="338" width="62" height="13"/>
              <rect class="cls-7" x="214" y="351" width="62" height="13"/>
              <rect x="214" y="364" width="62" height="13"/>
              <rect class="cls-7" x="214" y="377" width="62" height="13"/>
              <rect x="214" y="390" width="62" height="13"/>
              <rect class="cls-7" x="214" y="403" width="62" height="13"/>
              <rect x="214" y="416" width="62" height="13"/>
              <rect class="cls-7" x="214" y="429" width="62" height="13"/>
              <rect x="214" y="442" width="62" height="13"/>
              <rect class="cls-7" x="214" y="455" width="62" height="13"/>
              <rect x="334" y="260" width="62" height="13"/>
              <rect class="cls-7" x="334" y="273" width="62" height="13"/>
              <rect x="334" y="286" width="62" height="13"/>
              <rect class="cls-7" x="334" y="299" width="62" height="13"/>
              <rect x="334" y="312" width="62" height="13"/>
              <rect class="cls-7" x="334" y="325" width="62" height="13"/>
              <rect x="334" y="338" width="62" height="13"/>
              <rect class="cls-7" x="334" y="351" width="62" height="13"/>
              <rect x="334" y="364" width="62" height="13"/>
              <rect class="cls-7" x="334" y="377" width="62" height="13"/>
              <rect x="334" y="390" width="62" height="13"/>
              <rect class="cls-7" x="334" y="403" width="62" height="13"/>
              <rect x="334" y="416" width="62" height="13"/>
              <rect class="cls-7" x="334" y="429" width="62" height="13"/>
              <rect x="334" y="442" width="62" height="13"/>
              <rect class="cls-7" x="334" y="455" width="62" height="13"/>
            </g>

            <g transform="rotate(${d}, 306.49, 3565.75)">
              <ellipse class="cls-6" cx="306.49" cy="3565.75" rx="203.51" ry="203.75"/>
              <rect x="291.5" y="3465" width="25" height="203.5"/>
              <rect x="214" y="3465" width="62" height="13"/>
              <rect class="cls-7" x="214" y="3478" width="62" height="13"/>
              <rect x="214" y="3491" width="62" height="13"/>
              <rect class="cls-7" x="214" y="3504" width="62" height="13"/>
              <rect x="214" y="3517" width="62" height="13"/>
              <rect class="cls-7" x="214" y="3530" width="62" height="13"/>
              <rect x="214" y="3543" width="62" height="13"/>
              <rect class="cls-7" x="214" y="3556" width="62" height="13"/>
              <rect x="214" y="3569" width="62" height="13"/>
              <rect class="cls-7" x="214" y="3582" width="62" height="13"/>
              <rect x="214" y="3595" width="62" height="13"/>
              <rect class="cls-7" x="214" y="3608" width="62" height="13"/>
              <rect x="214" y="3621" width="62" height="13"/>
              <rect class="cls-7" x="214" y="3634" width="62" height="13"/>
              <rect x="214" y="3647" width="62" height="13"/>
              <rect class="cls-7" x="214" y="3660" width="62" height="13"/>
              <rect x="334" y="3465" width="62" height="13"/>
              <rect class="cls-7" x="334" y="3478" width="62" height="13"/>
              <rect x="334" y="3491" width="62" height="13"/>
              <rect class="cls-7" x="334" y="3504" width="62" height="13"/>
              <rect x="334" y="3517" width="62" height="13"/>
              <rect class="cls-7" x="334" y="3530" width="62" height="13"/>
              <rect x="334" y="3543" width="62" height="13"/>
              <rect class="cls-7" x="334" y="3556" width="62" height="13"/>
              <rect x="334" y="3569" width="62" height="13"/>
              <rect class="cls-7" x="334" y="3582" width="62" height="13"/>
              <rect x="334" y="3595" width="62" height="13"/>
              <rect class="cls-7" x="334" y="3608" width="62" height="13"/>
              <rect x="334" y="3621" width="62" height="13"/>
              <rect class="cls-7" x="334" y="3634" width="62" height="13"/>
              <rect x="334" y="3647" width="62" height="13"/>
              <rect class="cls-7" x="334" y="3660" width="62" height="13"/>
            </g>
            <g transform="rotate(${d}, 1849.49, 3565.75)">
             <ellipse class="cls-6" cx="1849.49" cy="3565.75" rx="203.75" ry="203.51"/>
              <rect x="1835.99" y="3461.51" width="25" height="203.5" transform="translate(5411.75 1714.76) rotate(90)"/>
              <rect x="1912.74" y="3497.76" width="62" height="13" transform="translate(5448 1560.51) rotate(90)"/>
              <rect class="cls-7" x="1899.74" y="3497.76" width="62" height="13" transform="translate(5435 1573.51) rotate(90)"/>
              <rect x="1886.74" y="3497.76" width="62" height="13" transform="translate(5422 1586.51) rotate(90)"/>
              <rect class="cls-7" x="1873.74" y="3497.76" width="62" height="13" transform="translate(5409 1599.51) rotate(90)"/>
              <rect x="1860.74" y="3497.76" width="62" height="13" transform="translate(5396 1612.51) rotate(90)"/>
              <rect class="cls-7" x="1847.74" y="3497.76" width="62" height="13" transform="translate(5383 1625.51) rotate(90)"/>
              <rect x="1834.74" y="3497.76" width="62" height="13" transform="translate(5370 1638.51) rotate(90)"/>
              <rect class="cls-7" x="1821.74" y="3497.76" width="62" height="13" transform="translate(5357 1651.51) rotate(90)"/>
              <rect x="1808.74" y="3497.76" width="62" height="13" transform="translate(5344 1664.51) rotate(90)"/>
              <rect class="cls-7" x="1795.74" y="3497.76" width="62" height="13" transform="translate(5331 1677.51) rotate(90)"/>
              <rect x="1782.74" y="3497.76" width="62" height="13" transform="translate(5318 1690.51) rotate(90)"/>
              <rect class="cls-7" x="1769.74" y="3497.76" width="62" height="13" transform="translate(5305 1703.51) rotate(90)"/>
              <rect x="1756.74" y="3497.76" width="62" height="13" transform="translate(5292 1716.51) rotate(90)"/>
              <rect class="cls-7" x="1743.74" y="3497.76" width="62" height="13" transform="translate(5279 1729.51) rotate(90)"/>
              <rect x="1730.74" y="3497.76" width="62" height="13" transform="translate(5266 1742.51) rotate(90)"/>
              <rect class="cls-7" x="1717.74" y="3497.76" width="62" height="13" transform="translate(5253 1755.51) rotate(90)"/>
              <rect x="1912.74" y="3617.76" width="62" height="13" transform="translate(5568 1680.51) rotate(90)"/>
              <rect class="cls-7" x="1899.74" y="3617.76" width="62" height="13" transform="translate(5555 1693.51) rotate(90)"/>
              <rect x="1886.74" y="3617.76" width="62" height="13" transform="translate(5542 1706.51) rotate(90)"/>
              <rect class="cls-7" x="1873.74" y="3617.76" width="62" height="13" transform="translate(5529 1719.51) rotate(90)"/>
              <rect x="1860.74" y="3617.76" width="62" height="13" transform="translate(5516 1732.51) rotate(90)"/>
              <rect class="cls-7" x="1847.74" y="3617.76" width="62" height="13" transform="translate(5503 1745.51) rotate(90)"/>
              <rect x="1834.74" y="3617.76" width="62" height="13" transform="translate(5490 1758.51) rotate(90)"/>
              <rect class="cls-7" x="1821.74" y="3617.76" width="62" height="13" transform="translate(5477 1771.51) rotate(90)"/>
              <rect x="1808.74" y="3617.76" width="62" height="13" transform="translate(5464 1784.51) rotate(90)"/>
              <rect class="cls-7" x="1795.74" y="3617.76" width="62" height="13" transform="translate(5451 1797.51) rotate(90)"/>
              <rect x="1782.74" y="3617.76" width="62" height="13" transform="translate(5438 1810.51) rotate(90)"/>
              <rect class="cls-7" x="1769.74" y="3617.76" width="62" height="13" transform="translate(5425 1823.51) rotate(90)"/>
              <rect x="1756.74" y="3617.76" width="62" height="13" transform="translate(5412 1836.51) rotate(90)"/>
              <rect class="cls-7" x="1743.74" y="3617.76" width="62" height="13" transform="translate(5399 1849.51) rotate(90)"/>
              <rect x="1730.74" y="3617.76" width="62" height="13" transform="translate(5386 1862.51) rotate(90)"/>
              <rect class="cls-7" x="1717.74" y="3617.76" width="62" height="13" transform="translate(5373 1875.51) rotate(90)"/>
             </g>

             <g transform="rotate(${d}, 1849.49, 364.75)">
              <ellipse class="cls-6" cx="1849.49" cy="364.75" rx="203.75" ry="203.51"/>
              <rect x="1835.99" y="260.51" width="25" height="203.5" transform="translate(2210.75 -1486.24) rotate(90)"/>
              <rect x="1912.74" y="296.76" width="62" height="13" transform="translate(2247 -1640.49) rotate(90)"/>
              <rect class="cls-7" x="1899.74" y="296.76" width="62" height="13" transform="translate(2234 -1627.49) rotate(90)"/>
              <rect x="1886.74" y="296.76" width="62" height="13" transform="translate(2221 -1614.49) rotate(90)"/>
              <rect class="cls-7" x="1873.74" y="296.76" width="62" height="13" transform="translate(2208 -1601.49) rotate(90)"/>
              <rect x="1860.74" y="296.76" width="62" height="13" transform="translate(2195 -1588.49) rotate(90)"/>
              <rect class="cls-7" x="1847.74" y="296.76" width="62" height="13" transform="translate(2182 -1575.49) rotate(90)"/>
              <rect x="1834.74" y="296.76" width="62" height="13" transform="translate(2169 -1562.49) rotate(90)"/>
              <rect class="cls-7" x="1821.74" y="296.76" width="62" height="13" transform="translate(2156 -1549.49) rotate(90)"/>
              <rect x="1808.74" y="296.76" width="62" height="13" transform="translate(2143 -1536.49) rotate(90)"/>
              <rect class="cls-7" x="1795.74" y="296.76" width="62" height="13" transform="translate(2130 -1523.49) rotate(90)"/>
              <rect x="1782.74" y="296.76" width="62" height="13" transform="translate(2117 -1510.49) rotate(90)"/>
              <rect class="cls-7" x="1769.74" y="296.76" width="62" height="13" transform="translate(2104 -1497.49) rotate(90)"/>
              <rect x="1756.74" y="296.76" width="62" height="13" transform="translate(2091 -1484.49) rotate(90)"/>
              <rect class="cls-7" x="1743.74" y="296.76" width="62" height="13" transform="translate(2078 -1471.49) rotate(90)"/>
              <rect x="1730.74" y="296.76" width="62" height="13" transform="translate(2065 -1458.49) rotate(90)"/>
              <rect class="cls-7" x="1717.74" y="296.76" width="62" height="13" transform="translate(2052 -1445.49) rotate(90)"/>
              <rect x="1912.74" y="416.76" width="62" height="13" transform="translate(2367 -1520.49) rotate(90)"/>
              <rect class="cls-7" x="1899.74" y="416.76" width="62" height="13" transform="translate(2354 -1507.49) rotate(90)"/>
              <rect x="1886.74" y="416.76" width="62" height="13" transform="translate(2341 -1494.49) rotate(90)"/>
              <rect class="cls-7" x="1873.74" y="416.76" width="62" height="13" transform="translate(2328 -1481.49) rotate(90)"/>
              <rect x="1860.74" y="416.76" width="62" height="13" transform="translate(2315 -1468.49) rotate(90)"/>
              <rect class="cls-7" x="1847.74" y="416.76" width="62" height="13" transform="translate(2302 -1455.49) rotate(90)"/>
              <rect x="1834.74" y="416.76" width="62" height="13" transform="translate(2289 -1442.49) rotate(90)"/>
              <rect class="cls-7" x="1821.74" y="416.76" width="62" height="13" transform="translate(2276 -1429.49) rotate(90)"/>
              <rect x="1808.74" y="416.76" width="62" height="13" transform="translate(2263 -1416.49) rotate(90)"/>
              <rect class="cls-7" x="1795.74" y="416.76" width="62" height="13" transform="translate(2250 -1403.49) rotate(90)"/>
              <rect x="1782.74" y="416.76" width="62" height="13" transform="translate(2237 -1390.49) rotate(90)"/>
              <rect class="cls-7" x="1769.74" y="416.76" width="62" height="13" transform="translate(2224 -1377.49) rotate(90)"/>
              <rect x="1756.74" y="416.76" width="62" height="13" transform="translate(2211 -1364.49) rotate(90)"/>
              <rect class="cls-7" x="1743.74" y="416.76" width="62" height="13" transform="translate(2198 -1351.49) rotate(90)"/>
              <rect x="1730.74" y="416.76" width="62" height="13" transform="translate(2185 -1338.49) rotate(90)"/>
              <rect class="cls-7" x="1717.74" y="416.76" width="62" height="13" transform="translate(2172 -1325.49) rotate(90)"/>
            </g>


          </g>`
    },

    svgintfour: function (svg, d) {
      svg.innerHTML = ''
      svg.innerHTML = `
                <g transform="rotate(${d}, 1079.41, 2025.83)" >
                  <rect class="cls-8" x="942.96" y="869" width="136.4" height="64.89"/>
                  <rect x="942.96" y="932.28" width="136.88" height="65.12"/>
                  <rect class="cls-8" x="942.96" y="998.78" width="136.4" height="64.89"/>
                  <rect x="942.96" y="1063.67" width="136.4" height="64.89"/>
                  <rect class="cls-8" x="942.96" y="1128.56" width="136.4" height="64.89"/>
                  <rect x="942.96" y="1191.83" width="136.88" height="65.12"/>
                  <rect class="cls-8" x="942.96" y="1258.34" width="136.4" height="64.89"/>
                  <rect x="942.96" y="1323.23" width="136.4" height="64.89"/>
                  <rect class="cls-8" x="942.96" y="1388.12" width="136.4" height="64.89"/>
                  <rect x="942.96" y="1451.39" width="136.88" height="65.12"/>
                  <rect class="cls-8" x="942.96" y="1517.9" width="136.4" height="64.89"/>
                  <rect x="942.96" y="1582.79" width="136.4" height="64.89"/>
                  <rect class="cls-8" x="942.96" y="1647.68" width="136.4" height="64.89"/>
                  <rect x="942.96" y="1710.95" width="136.88" height="65.12"/>
                  <rect class="cls-9" x="1079.84" y="869" width="136.4" height="64.89"/>
                  <rect class="cls-10" x="1079.84" y="932.28" width="136.88" height="65.12"/>
                  <rect class="cls-9" x="1079.84" y="998.78" width="136.4" height="64.89"/>
                  <rect class="cls-10" x="1079.84" y="1063.67" width="136.4" height="64.89"/>
                  <rect class="cls-9" x="1079.84" y="1128.56" width="136.4" height="64.89"/>
                  <rect class="cls-10" x="1079.84" y="1191.83" width="136.88" height="65.12"/>
                  <rect class="cls-9" x="1079.84" y="1258.34" width="136.4" height="64.89"/>
                  <rect class="cls-10" x="1079.84" y="1323.23" width="136.4" height="64.89"/>
                  <rect class="cls-9" x="1079.84" y="1388.12" width="136.4" height="64.89"/>
                  <rect class="cls-10" x="1079.84" y="1451.39" width="136.88" height="65.12"/>
                  <rect class="cls-9" x="1079.84" y="1517.9" width="136.4" height="64.89"/>
                  <rect class="cls-10" x="1079.84" y="1582.79" width="136.4" height="64.89"/>
                  <rect class="cls-9" x="1079.84" y="1647.68" width="136.4" height="64.89"/>
                  <rect class="cls-10" x="1079.84" y="1710.95" width="136.88" height="65.12"/>
                  <rect class="cls-10" x="1079.84" y="2618.76" width="136.88" height="65.12"/>
                  <rect class="cls-9" x="1079.84" y="2685.27" width="136.4" height="64.89"/>
                  <rect class="cls-10" x="1079.84" y="2750.16" width="136.4" height="64.89"/>
                  <rect class="cls-9" x="1079.84" y="2815.05" width="136.4" height="64.89"/>
                  <rect class="cls-10" x="1079.84" y="2359.2" width="136.88" height="65.12"/>
                  <rect class="cls-9" x="1079.84" y="2425.71" width="136.4" height="64.89"/>
                  <rect class="cls-10" x="1079.84" y="2490.6" width="136.4" height="64.89"/>
                  <rect class="cls-9" x="1079.84" y="2555.49" width="136.4" height="64.89"/>
                  <rect class="cls-10" x="1079.84" y="2878.32" width="136.88" height="65.12"/>
                  <rect class="cls-9" x="1079.84" y="2944.83" width="136.4" height="64.89"/>
                  <rect class="cls-10" x="1079.84" y="3009.72" width="136.4" height="64.89"/>
                  <rect class="cls-9" x="1079.84" y="3074.61" width="136.4" height="64.89"/>
                  <rect class="cls-10" x="1079.84" y="3137.88" width="136.88" height="65.12"/>
                  <rect class="cls-8" x="942.96" y="1777.46" width="136.4" height="64.89"/>
                  <rect x="942.96" y="1842.35" width="136.4" height="64.89"/>
                  <rect class="cls-8" x="942.96" y="1907.24" width="136.4" height="64.89"/>
                  <rect x="942.96" y="1970.51" width="136.88" height="65.12"/>
                  <rect class="cls-8" x="942.96" y="2037.02" width="136.4" height="64.89"/>
                  <rect x="942.96" y="2101.91" width="136.4" height="64.89"/>
                  <rect class="cls-8" x="942.96" y="2166.8" width="136.4" height="64.89"/>
                  <rect x="942.96" y="2230.07" width="136.88" height="65.12"/>
                  <rect class="cls-8" x="942.96" y="2296.57" width="136.4" height="64.89"/>
                  <rect x="942.96" y="2361.46" width="136.4" height="64.89"/>
                  <rect class="cls-8" x="942.96" y="2423.77" width="136.4" height="64.89"/>
                  <rect x="942.96" y="2487.05" width="136.88" height="65.12"/>
                  <rect class="cls-8" x="942.96" y="2553.55" width="136.4" height="64.89"/>
                  <rect x="942.96" y="2618.44" width="136.4" height="64.89"/>
                  <rect class="cls-8" x="942.96" y="2683.33" width="136.4" height="64.89"/>
                  <rect x="942.96" y="2746.61" width="136.88" height="65.12"/>
                  <rect class="cls-8" x="942.96" y="2813.11" width="136.4" height="64.89"/>
                  <rect x="942.96" y="2878" width="136.4" height="64.89"/>
                  <rect class="cls-8" x="942.96" y="2942.89" width="136.4" height="64.89"/>
                  <rect x="942.96" y="3006.16" width="136.88" height="65.12"/>
                  <rect class="cls-8" x="942.96" y="3072.67" width="136.4" height="64.89"/>
                  <rect x="942.96" y="3137.56" width="136.4" height="64.89"/>
                  <rect class="cls-8" x="1341.98" y="869" width="136.4" height="64.89"/>
                  <rect x="1341.98" y="932.28" width="136.88" height="65.12"/>
                  <rect class="cls-8" x="1341.98" y="998.78" width="136.4" height="64.89"/>
                  <rect x="1341.98" y="1063.67" width="136.4" height="64.89"/>
                  <rect class="cls-8" x="1341.98" y="1128.56" width="136.4" height="64.89"/>
                  <rect x="1341.98" y="1191.83" width="136.88" height="65.12"/>
                  <rect class="cls-8" x="1341.98" y="1258.34" width="136.4" height="64.89"/>
                  <rect x="1341.98" y="1323.23" width="136.4" height="64.89"/>
                  <rect class="cls-8" x="1341.98" y="1388.12" width="136.4" height="64.89"/>
                  <rect x="1341.98" y="1451.39" width="136.88" height="65.12"/>
                  <rect class="cls-8" x="1341.98" y="1517.9" width="136.4" height="64.89"/>
                  <rect x="1341.98" y="1582.79" width="136.4" height="584.01"/>
                  <rect class="cls-8" x="1341.98" y="2166.8" width="136.4" height="64.89"/>
                  <rect x="1341.98" y="2230.07" width="136.88" height="65.12"/>
                  <rect class="cls-8" x="1341.98" y="2296.57" width="136.4" height="64.89"/>
                  <rect x="1341.98" y="2361.46" width="136.4" height="64.89"/>
                  <rect class="cls-8" x="1341.98" y="2423.77" width="136.4" height="64.89"/>
                  <rect x="1341.98" y="2487.05" width="136.88" height="65.12"/>
                  <rect class="cls-8" x="1341.98" y="2553.55" width="136.4" height="64.89"/>
                  <rect x="1341.98" y="2618.44" width="136.4" height="64.89"/>
                  <rect class="cls-8" x="1341.98" y="2683.33" width="136.4" height="64.89"/>
                  <rect x="1341.98" y="2746.61" width="136.88" height="65.12"/>
                  <rect class="cls-8" x="1341.98" y="2813.11" width="136.4" height="64.89"/>
                  <rect x="1341.98" y="2878" width="136.4" height="64.89"/>
                  <rect class="cls-8" x="1341.98" y="2942.89" width="136.4" height="64.89"/>
                  <rect x="1341.98" y="3006.16" width="136.88" height="65.12"/>
                  <rect class="cls-8" x="1341.98" y="3072.67" width="136.4" height="64.89"/>
                  <rect x="1341.98" y="3137.56" width="136.4" height="64.89"/>
                  <rect x="1214.14" y="2166.8" width="127.84" height="419.68"/>
                  <rect x="1214.14" y="933.57" width="127.84" height="467.46"/>
                  <rect x="1079.84" y="2035.08" width="262.14" height="131.72"/>
                 </g>
                 `
    }

    //       createGrid: function (svg, bitrate) {
    //         svg.innerHTML = ''
    //         const HEIGHT = 3840
    //         const WIDTH = 2160
    //         console.log(bitrate)
    //         let gridH = Math.floor(convert(bitrate, 0, 20, 20, 10))
    //         let gridW = Math.floor(convert(bitrate, 0, 20, 20, 10))
    //
    //         for (let i = 0; i < gridH; i++) {
    //           for (let j = 0; j < gridW; j++) {
    //
    //
    //              svg.innerHTML += `<rect  id="rechteck-${i}" fill="none" stroke="rgb(200,200,200)" z-index="0" stroke-width="2" x="${WIDTH / gridW * i}" y="${HEIGHT / gridH * j}" width="${WIDTH / gridW}" height="${HEIGHT / gridH}"/>
    // `
    //
    //           }
    //         }
    //       }
  }
}
</script>

<style>
body {
  width: 100%;
  height: 100%;
  margin: 0;
  //background-color: rgba(20, 20, 230, 0.0);
}
svg {
  z-index: 9999;
  width: 2160px;
  height: 3840px;
  max-height: 90vmin;
  max-width: 50.63vmin;
  border: red 2px solid;
  display: inline-block;
}
g{
  width: 2160px;
  height: 3840px;

}
.cls-1{fill:#4d8bf7;}
.cls-2{fill: #d4145a;}
.cls-3{fill:none;stroke:#000000;stroke-width:15px;}

.cls-4{fill:#7cce65;}
.cls-5{fill:#d5e2d0;}

.cls-6{fill:none;stroke:#000;stroke-miterlimit:10;stroke-width:2px;}
.cls-7{fill: white;}

.cls-8{fill:#f0f;}
.cls-9{fill:#c7b299;}
.cls-10{fill:#d4145a;}
</style>
