<template>
  <div>
    <section id="Header">
      <router-link v-html="d.Pages[d.Pages.length - 2].title.rendered" :to="{name: 'Home'}"> </router-link>
    </section>
    <section id="p-text">
      <p v-html="d.Pages[d.Pages.length - 2].content.rendered">
      </p>
    </section>
  </div>
</template>

<script>
export default {
  name: 'AGB',
  data () {
    return {
      d: ''
    }
  },
  async mounted () {
    const page = await this.$store.dispatch('getPages')
    this.d = page
    console.log(this.d.Pages[0])
  }
}
</script>

<style scoped lang="stylus">
@import '~normalize.css';
@import '../CSS/global.styl';
@import '../CSS/scale.styl';
#Header
  text-align center
  font-family "IBM Plex Sans Text", sans-serif
  font-weight bold
  font-size 80px
  margin 0
  padding 5vh 1vw 5vh 1vw
#Header a
  color black
  transition: all 0.3s ease 0.1s
#Header a:hover
  letter-spacing 6px
  transition: all 0.3s ease 0.1s
  color orangered !important
p
  color black
  font-family "IBM Plex Sans", serif
  font-weight 400
  font-style normal
  font-size 28px
  line-height 37.4px
  padding 0.5vh 3vw 2vh 3vw
  margin-left auto
  margin-right auto
  width 95%
@media screen and (max-width 1200px)
  #Header
    font-size 50px
  #Header a:hover
    letter-spacing 0
  p
    font-size 30.4px
    line-height 40.8px
    margin-left auto
    margin-right auto
    width 95vw
@media screen and (max-width 1000px)
  #Header
    font-size 45px
  #Header a:hover
    letter-spacing 0
  p
    font-size 29px
    line-height 36px
@media screen and (max-width 600px)
  #Header
    font-size 42px
  #Header a:hover
    letter-spacing 0
  p
    font-size 20px
    line-height 27.2px
</style>
