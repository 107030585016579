<template>
  <div>
    <information />
    <Images />
    <section>
      <Footer />
    </section>
  </div>
</template>

<script>
import Information from '@/components/information'
import Images from '@/components/Images'
import Footer from '@/components/footer'
export default {
  name: 'Article',
  components: { Information, Images, Footer }
}
</script>

<style scoped lang="stylus">

</style>
