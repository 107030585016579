const wpApiHost = 'https://manuelrau.com'
const wpApiPathBase = `${wpApiHost}/wp-json`
const wpApiPath = `${wpApiPathBase}/wp/v2`
// const wpApiPathJwt = `${wpApiPathBase}/jwt-auth/v1`

const actions = {
  async getOverviewPosts () {
    const posts = await fetch(`${wpApiPath}/posts?per_page=30`).then(r => r.json())
    console.log(posts)
    const Title = []
    posts.forEach(p => {
      const T = p
      console.log(T)
      Title.push(T)
    })
    return {
      posts,
      Title
    }
  },
  async getCategories () {
    const Categories = await fetch(`${wpApiPath}/categories?per_page=30`).then(r => r.json())
    console.log(Categories)
    return {
      Categories
    }
  },
  async getPages () {
    const Pages = await fetch(`${wpApiPath}/pages`).then(a => a.json())
    console.log(Pages)
    return {
      Pages
    }
  },
  async getMedia () {
    const Media = await fetch(`${wpApiPath}/media?per_page=50`).then(a => a.json())
    const i = 'image'
    const f = 'file'
    const Image = Media.filter(a => a.media_type === i).map(a => a) // Filter Inhalte nach Bildern und gebe diese zurück
    const File = Media.filter(a => a.media_type === f).map(a => a) // Filter Inhalte nach Video und gebe diese zurück
    return {
      Image,
      File
    }
  },
  async getMediaCategory () {
    const MediaCategory = await fetch(`${wpApiPath}/media_category`).then(b => b.json())
    return {
      MediaCategory
    }
  }
}

const wpStoreModule = {
//   state,
//   mutations: {},
  actions
//   namespaced: true
}
//
export default wpStoreModule
