<template>

    <div>
    <video ref="video" autoplay></video>
    </div>

</template>

<script>

import * as faceapi from 'face-api.js'
import translate from '../../helpers/translation'

export default {
  name: 'face',

  mounted () {
    const that = this
    const video = this.$refs.video

    Promise.all([
      faceapi.nets.tinyFaceDetector.loadFromUri('https://rawcdn.githack.com/justadudewhohacks/face-api.js/a86f011d72124e5fb93e59d5c4ab98f699dd5c9c/weights/'),
      faceapi.nets.faceLandmark68Net.loadFromUri('https://rawcdn.githack.com/justadudewhohacks/face-api.js/a86f011d72124e5fb93e59d5c4ab98f699dd5c9c/weights/'),
      faceapi.nets.faceExpressionNet.loadFromUri('https://rawcdn.githack.com/justadudewhohacks/face-api.js/a86f011d72124e5fb93e59d5c4ab98f699dd5c9c/weights/'),
      faceapi.nets.ageGenderNet.loadFromUri('https://rawcdn.githack.com/justadudewhohacks/face-api.js/a86f011d72124e5fb93e59d5c4ab98f699dd5c9c/weights/')
    ]).then(() => {
      const getUserMedia = 'getUserMedia' in navigator
        ? function (opts) {
          return new Promise(function (resolve, reject) {
            navigator.getUserMedia(opts, resolve, reject)
          })
        }
        : function (opts) {
          return navigator.mediaDevices.getUserMedia(opts)
        }
      getUserMedia({ video: {} })
        .then(stream => {
          video.srcObject = stream
          // svgVideo ? svgVideo.srcObject = stream : null
        })
        .catch(err => {
          console.error(err)
        })
    })

    video.addEventListener('play', async () => {
      window.requestAnimationFrame(detectAndProcess)
    })

    async function detectAndProcess () {
      const detections = await faceapi.detectAllFaces(video, new faceapi.TinyFaceDetectorOptions())
        .withFaceLandmarks()
        .withFaceExpressions()
        .withAgeAndGender()

      const newDetections = translate(detections)
      console.log(newDetections)
      if (newDetections === undefined) {
        console.log('detection undefined')
      } else {
        that.$store.commit('saveDetections', newDetections)
      }
      //
      // let canvas = document.getElementById('video-canvas')
      // if (canvas) {
      //     let ctx = canvas.getContext('2d')
      //     ctx.fillStyle = 'rgb(32,75,251)'
      //     ctx.fillRect(0, 0, ctx.width, ctx.height)
      //     canvas.width = video.offsetWidth
      //     canvas.height = video.offsetHeight
      //
      //     for (let i = 0; i < detections.length; i++) {
      //         let positions = detections[i].landmarks.positions
      //
      //         let xArr = []
      //         let yArr = []
      //         for (let j = 0; j < positions.length; j++) {
      //             xArr.push(positions[j].x)
      //             yArr.push(positions[j].y)
      //         }
      //
      //         positions.forEach(position => {
      //             ctx.rect(
      //                 convert(position.x, 0, detections[i].detection.imageWidth, 0, video.offsetWidth),
      //                 convert(position.y, 0, detections[i].detection.imageHeight, 0, video.offsetHeight),
      //                 2,
      //                 2
      //             )
      //             ctx.fillStyle = 'white'
      //             ctx.fill()
      //         })
      //
      //         ctx.rect(0, 0, video.offsetWidth * 0.3419, video.offsetHeight)
      //         ctx.rect(video.offsetWidth * 0.66, 0, video.offsetWidth * 0.3419, video.offsetHeight)
      //         ctx.fillStyle = 'rgba(255, 255, 255, 0.4)'
      //         ctx.fill()
      //
      //         let minX = Math.min(...xArr)
      //         let maxX = Math.max(...xArr)
      //         let minY = Math.min(...yArr)
      //         let maxY = Math.max(...yArr)
      //         minX = convert(minX, 0, detections[i].detection.imageWidth, 0, video.offsetWidth)
      //         maxX = convert(maxX, 0, detections[i].detection.imageWidth, 0, video.offsetWidth)
      //         minY = convert(minY, 0, detections[i].detection.imageHeight, 0, video.offsetHeight)
      //         maxY = convert(maxY, 0, detections[i].detection.imageHeight, 0, video.offsetHeight)
      //         ctx.moveTo(minX, minY)
      //         ctx.lineTo(maxX, minY)
      //         ctx.lineTo(maxX, maxY)
      //         ctx.lineTo(minX, maxY)
      //         ctx.lineTo(minX, minY)
      //
      //         if (i === 1) {
      //             ctx.strokeStyle = 'rgb(251,75,31)'
      //         } else {
      //             ctx.strokeStyle = 'rgb(255,255,255)'
      //         }
      //         ctx.lineWidth = 1
      //         ctx.stroke()
      //     }
      //
      //
      //     translation(detections)
      window.requestAnimationFrame(detectAndProcess)
    }
  }
}
</script>

<style scoped>
</style>
