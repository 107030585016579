<template>
<div id="footer">
  <router-link :to="{ name: 'AGB'}">Manuel Rau 2022 Copyright</router-link>
</div>
</template>

<script>
export default {
  name: 'End'
}
</script>

<style scoped lang="stylus">
  #footer
    margin-right auto
    margin-left auto
    display flex
    justify-content right
    width 90vw
  #footer a
    font-size 4vh
    padding 1vh 0 1vh 0
    font-family "IBM Plex Mono", sans-serif
@media screen and (max-width 1200px)
  #footer a
    font-size 28px
  #footer
    justify-content center
@media screen and (max-width 1000px)
  #footer a
    font-size 24px
  #footer
    justify-content center
@media screen and (max-width 600px)
  #footer a
    font-size 22px
  #footer
    justify-content center
</style>
